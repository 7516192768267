import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const OutsideClick = ({ onToggle, children }) => {
  const wrapperRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const setWrapperRef = (node) => {
    wrapperRef.current = node;
  };
  const handleOutsideClick = (event) => {
    const outside = wrapperRef && !wrapperRef.current.contains(event.target);
    if (outside) {
      onToggle();
    }
  };

  return (
    <div className={"outside-click"} ref={setWrapperRef}>
      {children}
    </div>
  );
};

OutsideClick.propTypes = {
  children: PropTypes.node,
  onToggle: PropTypes.func,
};

export default OutsideClick;
